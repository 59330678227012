<template>
  <!-- 增加修改 -->
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

<!--      <a-form-model-item label="Client" prop="deptId" v-if="deptOptions.length">-->
<!--        <a-tree-select-->
<!--          v-model="form.deptId"-->
<!--          style="width: 100%"-->
<!--          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"-->
<!--          :tree-data="deptOptions"-->
<!--          placeholder=""-->
<!--          :replaceFields="replaceFields"-->
<!--          tree-default-expand-all-->
<!--        >-->
<!--        </a-tree-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="手机号" prop="phoneNumber">-->
<!--        <a-input v-model="form.phoneNumber" placeholder="请输入" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Account Email:" prop="email">
        <a-input v-model="form.email" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="First Name:" prop="userName" v-if="form.id == undefined">
        <a-input v-model="form.userName" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="Last Name:" prop="nickName">
        <a-input v-model="form.nickName" placeholder="" :maxLength="30" />
      </a-form-model-item>
      <a-form-model-item label="Passwords:" prop="password" v-if="form.id == undefined">
        <a-input-password v-model="form.password" placeholder="" :maxLength="20" />
      </a-form-model-item>
<!--      <a-form-model-item label="性别" prop="sex">-->
<!--        <a-radio-group v-model="form.sex" button-style="solid">-->
<!--          <a-radio-button v-for="(d, index) in sexOptions" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button>-->
<!--        </a-radio-group>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Status" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
<!--      <a-form-model-item label="岗位" prop="postIds">-->
<!--        <a-select-->
<!--          mode="multiple"-->
<!--          v-model="form.postIds"-->
<!--          placeholder="请选择"-->
<!--        >-->
<!--          <a-select-option v-for="(d, index) in postOptions" :key="index" :value="d.id">-->
<!--            {{ d.postName }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Role:" prop="roleIds">
        <a-select
          v-model="form.roleIds"
          placeholder=""
        >
          <a-select-option v-for="(d, index) in roleOptions" :key="index" :value="d.id">
            {{ d.roleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark">-->
<!--        <a-input v-model="form.remark" placeholder="请输入" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getUser, addUser, updateUser } from '@/api/system/user'

export default {
  name: 'CreateForm',
  props: {
    deptOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    },
    sexOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      // 岗位选项
      postOptions: [],
      // 角色选项
      roleOptions: [],
      // 默认密码
      initPassword: undefined,
      formTitle: '',
      // 表单参数
      form: {
        id: undefined,
        deptId: 0,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phoneNumber: undefined,
        email: undefined,
        sex: 0,
        status: 1,
        remark: undefined,
        postIds: [],
        roleIds: null
      },
      open: false,
      rules: {
        userName: [
          { required: true, message: 'First Name cannot be empty', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: 'Last Name cannot be empty', trigger: 'blur' }
        ],
        deptId: [
          { required: true, message: 'Department cannot be empty', trigger: 'change' }
        ],
        roleIds:[{
          required: true, message: 'Role cannot be empty',
        }],
        password: [
          { required: true, message: 'Password cannot be empty', trigger: 'blur' },
          { min: 5, max: 20, message: 'The length of the user password must be between 5 and 20', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Email cannot be empty', trigger: 'blur' },
          {
            type: 'email',
            message: 'Please fill in the email address correctly',
            trigger: ['blur', 'change']
          }
        ],
        phoneNumber: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请正确填写手机号',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    // this.getConfigKey('sys.user.initPassword').then(response => {
    //   this.initPassword = response.msg
    // })
  },
  watch: {
  },
  computed: {
    userInfo() {
      return this.$store.getters.info;
    }
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: undefined,
        deptId: undefined,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phoneNumber: undefined,
        email: undefined,
        sex: 0,
        status: 1,
        remark: undefined,
        postIds: [],
        roleIds: []
      }
    },
     /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.$emit('select-tree')
      getUser().then(response => {
        this.postOptions = response.data.posts
        console.log(this.userInfo.roleList[0].roleKey);

        if(this.userInfo.roleList[0].roleKey == 'brandSide') {
          this.roleOptions = response.data.roles.filter(i =>i.id == 18)
        } else if(this.userInfo.roleList[0].roleKey == 'brandSub') {
          this.roleOptions = [];
        } else if(this.userInfo.roleList[0].roleKey == 'admin' || this.userInfo.roleList[0].roleKey == 'platform' ) {
          this.roleOptions = response.data.roles
        }

        this.open = true
        this.formTitle = 'Create Account'
        this.form.password = this.initPassword
      })
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.$emit('select-tree')
      const userId = row ? row.id : ids
      getUser(userId).then(response => {
        this.form = response.data.data
        this.postOptions = response.data.posts
        this.roleOptions = response.data.roles
        this.form.postIds = response.data.postIds
        this.form.roleIds = response.data.roleIds.join(",")
        this.open = true
        this.formTitle = 'Modify Account'
        this.form.password = undefined
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form.roleIds);
      this.form.roleIds = this.form.roleIds.split(',')
      // return;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
